// type ResultsData = {
//     matches: any[];
//     query: string;
//     results: any[];
// }


export async function search(query: string, catalog?: string, limit?: number): Promise<any> {
    try {
        let url = `/search/?q=${query}`;
        if (catalog) {
            url += `&catalog=${catalog}`;
        }
        if (limit) {
            url += `&limit=${limit}`;
        }
        // Fetch Data from external Source
        const result = await fetch(
            url,
            { headers: { 'X-Requested-With': 'XMLHttpRequest' }}
        );
        return await result.json();
    } catch (error) {
        return error;
    }
}

export default function initSearch(elSearch: HTMLElement|null, elResults: HTMLElement|null, elModal: HTMLElement|null) {

    if (!elSearch || !elResults || !elModal) {
        return;
    }

    elModal.addEventListener('shown.bs.modal', function () {
        elSearch.focus();
    })

    elSearch.addEventListener('input', async (ev) => {

        const query = (ev.target as HTMLInputElement).value;

        if (query) {

            const data = await search(query);
            elResults.innerHTML = '';

            for (const listName of Object.keys(data.results)) {
                const listResults = data.results[listName].results;
                const listCount = data.results[listName].count;
                if (listResults.length > 0) {
                    const ul = document.createElement('ul');
                    const liHead = document.createElement('li');
                    ul.appendChild(liHead);
                    liHead.className = 'list-group-item bg-dark text-light';
                    liHead.appendChild(document.createTextNode(listName));
                    ul.className = 'list-group bg-white mb-3';
                    for (const d of listResults) {
                        // console.log(d);
                        const li = document.createElement('li');
                        const a = document.createElement('a');
                        ul.appendChild(li);
                        li.appendChild(a);
                        li.className = 'list-group-item';
                        a.href = d.url;
                        a.appendChild(document.createTextNode(d.title));
                    }
                    if (listResults.length < listCount) {
                        const slug = listName.replace(' ', '-').toLowerCase();
                        const li = document.createElement('li');
                        const a = document.createElement('a');
                        ul.appendChild(li);
                        li.appendChild(a);
                        li.className = 'list-group-item';
                        a.href = `/search/?q=${query}#${slug}`;
                        a.classList.add('btn', 'btn-primary', 'btn-sm')
                        a.appendChild(document.createTextNode('View all...'));
                    }

                    elResults.appendChild(ul);
                }
            }
        } else {
            elResults.innerHTML = '';
            // elMore.classList.add('d-none');
        }

        // ((elModal as unknown) as any).handleUpdate();

    });

}