// import autoComplete from "@tarekraafat/autocomplete.js";
import initLg from "./js/img-grid";
import initSearch from "./js/search";
import initForms from "./js/forms";

import { Carousel } from 'bootstrap';

document.addEventListener('DOMContentLoaded', function() {

    // expose Carousel to window object so it can be used by Vue
    // this also prevents bootstrap from being removed by webpack
    (window as any).Carousel = Carousel;

    // init page scripts
    initLg(document.getElementById('lg'));
    initSearch(
        document.getElementById('search'),
        document.getElementById('search-results'),
        document.getElementById('search-modal')
    );
    initForms();

});