export default function initLG(el: HTMLElement|null) {

    if (!el) { return; }

    const thumbs = [] as Record<string, string>[];
    const handleLG = function(ev: Event, index: number) {

        ev.preventDefault();

        // type assertion to allow us to use jQuery from the page
        (window as any).jQuery(ev.currentTarget).lightGallery({
            index: index,
            fullScreen: false,
            hash: false,
            share: false,
            rotate: false,
            autoplay: false,
            autoplayControls: false,
            pager: false,
            zoom: false,
            download: false,
            currentPagerPosition: 'middle',
            toogleThumb: false,
            dynamic: true,
            dynamicEl: thumbs,
        });
    }

    // add click event to main galley image
    el.querySelector('.img-main')?.addEventListener('click', (ev) => { handleLG(ev, 0); });

    const imgGrid = el.querySelector('div.image-grid');

    if (imgGrid) {
        for (const [i, a] of imgGrid.querySelectorAll('a').entries())  {
            const img = a.querySelector('img');
            if (img) {
                thumbs.push({
                    src: a.href,
                    thumb: img.src,
                    subHtml: img.alt,
                });
            }
            // add click event to thumbnails
            a.addEventListener('click', (ev) => { handleLG(ev, i); });
        }
    }

}