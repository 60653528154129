import flatpickr from "flatpickr";

export default function initForms() {

    const forms = document.getElementsByClassName('pardot-form') as HTMLCollectionOf<HTMLFormElement>;
    for (const form of forms) {

        const fp = flatpickr('.date-field', {
            altInput: true,
            minDate: 'today',
        });

        // console.log('name:', form.dataset.formName);
        // console.log('type:', form.dataset.formType);
        // console.log('recaptcha:', form.dataset.recaptchaSiteKey);

        form.addEventListener('submit', async (ev: Event) => {

            // don't submit form for now...
            ev.preventDefault();

            const form = ev.target as HTMLFormElement;
            const recaptchaSiteKey = form.dataset.recaptchaSiteKey || '';

            const handleForm = async function(recaptchaToken?: string) {
                const csrfToken = form.csrfmiddlewaretoken.value;
                form.csrfmiddlewaretoken.disabled = true;
                const formData = new FormData(form);
                formData.append('form_name', form.dataset.formName || '');
                formData.append('form_type', form.dataset.formType || '');
                if (recaptchaToken) {
                    formData.append('recaptcha_token', recaptchaToken);
                }
                const plainFormData = Object.fromEntries(formData.entries());

                try {
                    const url = `/form-submit/`;
                    const response = await fetch(
                        url, {
                            method: 'POST',
                            headers: {
                                'X-CSRFToken': csrfToken,
                                // 'X-Requested-With': 'XMLHttpRequest'
                            },
                            body: JSON.stringify(plainFormData)
                        }
                    );

                    const responseData = await response.json();

                    if (responseData.form && responseData.status) {
                        // ok to proceed
                        // console.log(responseData);
                        // form.submit();
                        window.location.href = form.action;
                    } else {
                        console.log('--- Error with submission');
                        console.log(responseData);
                    }

                } catch (error) {
                    return error;
                }
            };

            if (recaptchaSiteKey) {
                (window as any).grecaptcha.ready(function() {
                    (window as any).grecaptcha.execute(recaptchaSiteKey, {action: 'submit'}).then(async function(recaptchaToken: string) {
                        // Add your logic to submit to your backend server here.
                        handleForm(recaptchaToken);
                    });
                });
            } else {
                handleForm();
            }

        });
    }

}